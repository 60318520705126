import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import InternetPackage from '../utils/InternetPackage';
import Package from './package/package.js';
import PackageToggle from './package-toggle/package-toggle.js';
import Slider from 'react-slick';
import TvPackage from '../utils/TvPackage';
import cx from 'classnames';
import { orderBy } from 'lodash';
import { packageType } from '../../constants/packages';
import peacemaker from '../../images/index/peacemaker-badge.png';

const packageSize = (i) => {
	switch (i) {
		case 0:
			return 'mid';
		case 1:
			return 'big';
		case 2:
			return 'xl';
		case 3:
			return 'xxl';
		default:
			return 'big';
	}
};

const activePackages = (currPage, data) => {
	switch (currPage) {
		case 'index':
			return data.index.edges;
		case 'za-doma':
			return data.homePackages.edges;
		case 'za-biznesa':
			return data.forBusiness.edges;
		case 'za-biznesa-bezsrochni':
			return data.forBusinessIndefinite.edges;
		default:
			return data.homePackagesIndefinite.edges;
	}
};

const packageToggleOptions = [
	{
		text: 'Пакети',
		short: 'Пакети',
		icon: peacemaker
	},
	{
		text: 'Интернет',
		short: 'Нет',
		icon: peacemaker
	},
	{
		text: 'Телевизия',
		short: 'Тв',
		icon: undefined
	}
];

const PackageSection = ({
	enableToggle,
	readOnly,
	isDarkBackground,
	btnName,
	isIndexPage = true,
	currPage = 'index'
}) => {
	const data = useStaticQuery(graphql`
		query HomePackagesComponentQuery {
			homePackages: allGoogleDevonlynewdesignSheet(
				filter: {
					packageId: {
						in: [
							1
							2
							3
							4
							5
							6
							7
							8
							9
							16
							17
							18
							19
							20
							21
							25
							26
							27
							28
							30
							31
							32
							33
							34
						]
					}
				}
			) {
				edges {
					node {
						id
						packageid: packageId
						speed
						package
						tvcount: tvCount
						price
						internalname: internalName
						promotionalPrice
						hdChannels
						informationButton
						timeShift
						archive
						record
						ont
						ontColor
						ontHeader
						ontcount: ontCount
						batteryTipTokPriceExtras
						promoBadgeInfoTooltip
						timeShift5
						timeShift7
						staticIpPrice
						textMostOrdered
						mostOrderedBgColor
						mostOrderedTextColor
						textPromoOrdered
						promoOrderedTextColor
						promoOrderedBgColor
						textPromoBadge
						promoBadgeBgColor
						promoBadgeTextColor
						batteryTipTokPriceExtras
						orderMobile
						channelId
						tooltipStbAurora
						tooltipOnt
						hboCinemaxPackPrice
						diemaExtraPackPrice
						maxSportPackPrice
						sevenEightPackPrice
					}
				}
			}
			index: allGoogleDevonlynewdesignSheet(
				filter: { packageId: { in: [22, 23, 24] } }
			) {
				edges {
					node {
						id
						packageid: packageId
						speed
						package
						tvcount: tvCount
						price
						internalname: internalName
						promotionalPrice
						hdChannels
						informationButton
						timeShift
						archive
						record
						ont
						ontColor
						ontHeader
						ontcount: ontCount
						batteryTipTokPriceExtras
						promoBadgeInfoTooltip
						timeShift5
						timeShift7
						staticIpPrice
						textMostOrdered
						mostOrderedBgColor
						mostOrderedTextColor
						textPromoOrdered
						promoOrderedTextColor
						promoOrderedBgColor
						textPromoBadge
						promoBadgeBgColor
						promoBadgeTextColor
						batteryTipTokPriceExtras
						orderMobile
						channelId
						tooltipStbAurora
						tooltipOnt
						hboCinemaxPackPrice
						diemaExtraPackPrice
						maxSportPackPrice
						sevenEightPackPrice
					}
				}
			}
			homePackagesIndefinite: allGoogleDevonlynewdesignbezsrochniSheet(
				filter: {
					packageId: {
						in: [
							1
							2
							3
							4
							5
							6
							7
							8
							9
							16
							17
							18
							19
							20
							21
							25
							26
							27
							28
							30
							31
							32
							33
							34
						]
					}
				}
			) {
				edges {
					node {
						id
						packageid: packageId
						speed
						package
						tvcount: tvCount
						price
						internalname: internalName
						promotionalPrice
						hdChannels
						informationButton
						timeShift
						archive
						record
						ont
						ontColor
						ontHeader
						ontcount: ontCount
						batteryTipTokPriceExtras
						promoBadgeInfoTooltip
						timeShift5
						timeShift7
						staticIpPrice
						textMostOrdered
						mostOrderedBgColor
						mostOrderedTextColor
						textPromoOrdered
						promoOrderedTextColor
						promoOrderedBgColor
						textPromoBadge
						promoBadgeBgColor
						promoBadgeTextColor
						batteryTipTokPriceExtras
						orderMobile
						channelId
						tooltipStbAurora
						tooltipOnt
						hboCinemaxPackPrice
						diemaExtraPackPrice
						maxSportPackPrice
						sevenEightPackPrice
					}
				}
			}
			forBusiness: allGoogleBizdevnewSheet(
				filter: {
					packageId: {
						in: [
							1
							2
							3
							4
							5
							6
							7
							8
							9
							16
							17
							18
							19
							20
							21
							25
							26
							27
							28
							30
							31
							32
							33
							34
						]
					}
				}
			) {
				edges {
					node {
						id
						packageid: packageId
						speed
						package
						tvcount: tvCount
						price
						internalname: internalName
						promotionalPrice
						hdChannels
						informationButton
						timeShift
						archive
						record
						ont
						ontColor
						ontHeader
						ontcount: ontCount
						batteryTipTokPriceExtras
						promoBadgeInfoTooltip
						timeShift5
						timeShift7
						staticIpPrice
						textMostOrdered
						mostOrderedBgColor
						mostOrderedTextColor
						textPromoOrdered
						promoOrderedTextColor
						promoOrderedBgColor
						textPromoBadge
						promoBadgeBgColor
						promoBadgeTextColor
						batteryTipTokPriceExtras
						orderMobile
						channelId
						tooltipStbAurora
						tooltipOnt
						hboCinemaxPackPrice
						diemaExtraPackPrice
						maxSportPackPrice
						sevenEightPackPrice
					}
				}
			}
			forBusinessIndefinite: allGoogleBizdevnewbezsroschniSheet(
				filter: {
					packageId: {
						in: [
							1
							2
							3
							4
							5
							6
							7
							8
							9
							16
							17
							18
							19
							20
							21
							25
							26
							27
							28
							30
							31
							32
							33
							34
						]
					}
				}
			) {
				edges {
					node {
						id
						packageid: packageId
						speed
						package
						tvcount: tvCount
						price
						internalname: internalName
						promotionalPrice
						hdChannels
						informationButton
						timeShift
						archive
						record
						ont
						ontColor
						ontHeader
						ontcount: ontCount
						batteryTipTokPriceExtras
						promoBadgeInfoTooltip
						timeShift5
						timeShift7
						staticIpPrice
						textMostOrdered
						mostOrderedBgColor
						mostOrderedTextColor
						textPromoOrdered
						promoOrderedTextColor
						promoOrderedBgColor
						textPromoBadge
						promoBadgeBgColor
						promoBadgeTextColor
						batteryTipTokPriceExtras
						orderMobile
						channelId
						tooltipStbAurora
						tooltipOnt
						hboCinemaxPackPrice
						diemaExtraPackPrice
						maxSportPackPrice
						sevenEightPackPrice
					}
				}
			}
		}
	`);

	const usedData = activePackages(currPage, data);

	const internetPackages = usedData
		.filter((node) => node.node.package === packageType.internet)
		.map(({ node }, index) => {
			return new InternetPackage(
				node.packageid,
				packageSize(index),
				node.speed,
				node.price,
				{ ...node }
			);
		});

	const combinedPackages = usedData
		.filter((node) => node.node.package === packageType.package)
		.map(({ node }, index) => {
			let tempIndex = index;

			return new TvPackage(
				node.packageid,
				packageSize(tempIndex),
				node.speed,
				node.price,
				{ ...node },
				node.package,
				'',
				node.tvcount,
				node.ont,
				node.ontcount
			);
		});

	const tvPackages = usedData
		.filter((node) => node.node.package === packageType.tv)
		.map(({ node }, index) => {
			let tempIndex = index;

			return new TvPackage(
				node.packageid,
				packageSize(tempIndex),
				node.speed,
				node.price,
				{ ...node },
				node.package,
				'',
				node.tvcount,
				node.ont,
				node.ontcount
			);
		});

	const [isActive, setIsActive] = useState('Пакети');
	const [extendPackages, setExtendPackages] = useState(false);

	const [renderMethod, setRenderMethod] = useState(false);

	const selectedPackages = useMemo(() => {
		switch (isActive) {
			case 'Пакет':
				return combinedPackages;
			case 'Интернет':
				return internetPackages;
			case 'Телевизия':
				return tvPackages;

			default:
				return combinedPackages;
		}
	}, [isActive, tvPackages, combinedPackages, internetPackages]);

	useEffect(() => {
		if (typeof window !== `undefined`) {
			const type = new URLSearchParams(window.location.search).get('type');
			if (type === 'internet') {
				setIsActive('Интернет');
			}
			if (type === 'tv') {
				setIsActive('Телевизия');
			}
		}
		setRenderMethod(true);
	}, []);

	return (
		<section id="package-section">
			<div className="text-center row d-flex justify-content-center">
				{enableToggle && !isIndexPage && (
					<PackageToggle
						isActive={isActive}
						setIsActive={setIsActive}
						options={packageToggleOptions}
						currPage={currPage}
					/>
				)}
				{isIndexPage && (
					<Link
						to={'/za-doma/'}
						className="btn btn-primary dark-blue"
						id="pcta-header"
					>
						{btnName}
					</Link>
				)}
				<div className="row d-flex justify-content-center reasons-slider w-100">
					{renderMethod && (
						<RenderPackages
							packageList={
								isIndexPage ? combinedPackages : selectedPackages
							}
							isActive={isActive}
							extend={extendPackages}
							setExtend={(v) => setExtendPackages(v)}
							readOnly={readOnly}
							isDarkBackground={isDarkBackground}
							isIndexPage={isIndexPage}
						/>
					)}
				</div>
			</div>
		</section>
	);
};

const RenderPackages = ({
	extend,
	setExtend,
	packageList,
	isActive,
	readOnly,
	isDarkBackground,
	includePromoCode,
	isIndexPage
}) => {
	const sliderPage = new URLSearchParams(window.location.search).get('package');
	const [activeSliderMobile, setActiveSliderMobile] = useState(Number(sliderPage) || 0);
	const [activeSlider, setActiveSlider] = useState(0);
	const sliderBreakpoint = packageList.length <= 3 ? 'lg' : 'xl';
	const slider = useRef(null);
	const sliderMobile = useRef(null);
	const hideChannels = packageList.findIndex(
		({ includedChannelImages }) => includedChannelImages !== null
	);

	const settings = {
		speed: 1000,
		arrows: false,
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 4,
		dots: false
	};

	const settings_mobile = {
		initialSlide: Number(sliderPage) || 0,
		beforeChange: (current, next) => setActiveSliderMobile(next),
		speed: 200,
		focusOnSelect: true,
		className: 'w-100',
		pauseOnFocus: true,
		arrows: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '300px',
		responsive: [
			{
				breakpoint: 860,
				settings: {
					centerPadding: '250px'
				}
			},
			{
				breakpoint: 760,
				settings: {
					centerPadding: '220px'
				}
			},
			{
				breakpoint: 610,
				settings: {
					centerPadding: '170px'
				}
			},
			{
				breakpoint: 500,
				settings: {
					centerPadding: '120px'
				}
			},
			{
				breakpoint: 450,
				settings: {
					centerPadding: '90px'
				}
			},
			{
				breakpoint: 400,
				settings: {
					centerPadding: '80px'
				}
			},
			{
				breakpoint: 360,
				settings: {
					centerPadding: '65px'
				}
			}
		]
	};

	const dots = useMemo(() => {
		const dots = [];
		for (let i = 0; i < packageList.length; i++) {
			if (i % 4 === 0) {
				dots.push(i);
			}
		}
		return dots;
	}, [packageList]);

	const dotsMobile = useMemo(() => {
		const dots = [];
		for (let i = 0; i < packageList.length; i++) {
			dots.push(i);
		}
		return dots;
	}, [packageList]);

	useEffect(() => {
		const resetSlider = () => slider?.current?.slickGoTo(0);
		setActiveSlider(0);
		resetSlider();
	}, [isActive]);

	return (
		<>
			<div className={`d-block d-${sliderBreakpoint}-none w-100 packages-list`}>
				<div
					id="mobile"
					className={`relative flex w-100 reasons-slider-${!!isDarkBackground}`}
				>
					<Slider {...settings_mobile} ref={sliderMobile}>
						{orderBy(packageList, 'orderMobile', 'asc').map((p, index) => (
							<div
								key={index}
								className={'w-100 d-flex justify-content-center mt-5'}
							>
								<Package
									isScaledDown={index !== 1}
									isMiddle={index === 1}
									packageInfo={p}
									extend={extend}
									setExtend={setExtend}
									btnId={index}
									readOnly={readOnly}
									includePromoCode={includePromoCode}
									hideChannels={hideChannels === -1}
								/>
							</div>
						))}
					</Slider>

					<ul className="slick-dots d-block">
						{dotsMobile.map((i) => (
							<li
								id={i}
								className={cx('', {
									'slick-active': activeSliderMobile === i
								})}
								onClick={(e) =>
									sliderMobile?.current?.slickGoTo(
										Number(e.currentTarget.id)
									)
								}
							>
								<button>{i}</button>
							</li>
						))}
					</ul>
				</div>
			</div>

			<div
				className={`container d-none d-${sliderBreakpoint}-block relative`}
				style={{ paddingRight: 0, paddingLeft: 0 }}
			>
				<div className="row d-flex justify-content-center">
					<div className={`w-100 reasons-slider-${!!isDarkBackground}`}>
						<Slider
							ref={slider}
							{...settings}
							afterChange={(i) => setActiveSlider(i)}
						>
							{orderBy(packageList, 'id', 'asc').map((p, index) => (
								<div
									key={index}
									className={'w-100 d-flex justify-content-center mt-5'}
								>
									<Package
										isScaledDown={index !== 1}
										isMiddle={index === 1}
										packageInfo={p}
										extend={extend}
										setExtend={setExtend}
										btnId={index}
										readOnly={readOnly}
										includePromoCode={includePromoCode}
										hideChannels={hideChannels === -1}
									/>
								</div>
							))}
						</Slider>
						{!isIndexPage && (
							<div>
								<ul className="slick-dots d-block">
									{dots.map((i) => (
										<li
											id={i}
											className={cx('', {
												'slick-active': activeSlider === i
											})}
											onClick={(e) =>
												slider?.current?.slickGoTo(
													Number(e.currentTarget.id)
												)
											}
										>
											<button>{i}</button>
										</li>
									))}
								</ul>
								<span
									className={cx('arrow-prev', {
										'd-none': activeSlider === 0
									})}
									onClick={() => slider?.current?.slickPrev()}
								/>
								<span
									className={cx('arrow-next', {
										'd-none':
											activeSlider > dots.length ||
											(packageList.length < 4 && activeSlider === 0)
									})}
									onClick={() => slider?.current?.slickNext()}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default PackageSection;

